<template>
  <v-container fluid>
    <LineChart
      v-if="!isObjectEmpty(statisticChartDataPayments)"
      :chart-data="isOyAmountType ? statisticChartDataPayments : getModifiedStatisticChartDataPayments"
      :chart-id="chartId"
      :chart-options="chartOptions"
      :css-classes="cssClasses"
      :dataset-id-key="datasetIdKey"
      :height="height"
      :plugins="plugins"
      :styles="styles"
      :width="width"
    />
    <div class="d-flex mt-4">
      <div>
        <p class="mb-0">
          Загальна сума:
          <span v-if="!isObjectEmpty(statisticChartDataPayments)" class="font-weight-bold">
            {{ statisticChartDataPayments.totalAmount }} грн.
          </span>
        </p>
        <p class="mb-0">
          Загальна кількість:
          <span v-if="!isObjectEmpty(statisticChartDataPayments)" class="font-weight-bold">
            {{ statisticChartDataPayments.totalCount }} шт.
          </span>
        </p>
        <p class="mb-0">
          Середній чек:
          <span v-if="!isObjectEmpty(statisticChartDataPayments)" class="font-weight-bold">
            {{ statisticChartDataPayments.averageAmount }} грн.
          </span>
        </p>
      </div>
      <div class="ml-16">
        <v-checkbox v-model="isOyAmountType" label="Кількість по осі Y"></v-checkbox>
      </div>
    </div>
    <div v-if="isLegendTooltipVisible" :style="classObject" class="custom-tooltip">
      <div class="custom-tooltip-title d-flex align-center">
        <div :style="{ backgroundColor: legendTooltipData.color }"></div>
        <div>
          {{ legendTooltipData.title }}
          <span v-if="legendTooltipData.totalPercents">({{ legendTooltipData.totalPercents }}%)</span>
        </div>
      </div>
      <div>Сума: {{ legendTooltipData.totalAmount }} грн.</div>
      <div>Сер. платіж: {{ legendTooltipData.averageAmount }} грн.</div>
    </div>
  </v-container>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs/legacy'
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'FinancialStatementPayments',

  components: { LineChart },

  props: {
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const vueInstance = this

    return {
      isOyAmountType: false,
      isLegendTooltipVisible: false,
      legendTooltipData: {
        title: '',
        totalPercents: null,
        totalAmount: null,
        averageAmount: null,
        color: '',
      },
      classObject: {
        top: '',
        left: '',
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            align: 'start',
            onHover: function (event, legendItem) {
              vueInstance.showLegendTooltip(event, legendItem)
            }.bind(vueInstance),
            onLeave: function () {
              vueInstance.hideLegendTooltip()
            }.bind(vueInstance),
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                const label = context.dataset.label || ''
                const percent = context.dataset.percents[context.dataIndex]
                return `${label} (${percent}%)`
              },
              afterLabel: function (context) {
                const count = vueInstance.isOyAmountType
                  ? context.dataset.data[context.dataIndex]
                  : context.dataset.originData[context.dataIndex]
                const sum = context.dataset.amount[context.dataIndex]
                return [`Кількість: ${count} шт.`, `Сума: ${sum} грн.`]
              },
            },
          },
        },
      },
    }
  },

  computed: {
    ...mapState('payments', ['statisticChartPayload', 'statisticChartDataPayments']),
    ...mapGetters('payments', ['getModifiedStatisticChartDataPayments']),
  },

  watch: {
    statisticChartPayload: {
      handler(newValue) {
        this.initialize(newValue)
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    isObjectEmpty,
    ...mapActions('payments', ['loadStatisticPayments']),

    initialize(val) {
      this.loadStatisticPayments(val)
    },
    showLegendTooltip(event, legendItem) {
      const currentItem = this.statisticChartDataPayments.datasets[legendItem.datasetIndex]
      this.legendTooltipData.title = currentItem.label
      this.legendTooltipData.totalPercents = currentItem.totalPercents
      this.legendTooltipData.totalAmount = currentItem.totalAmount
      this.legendTooltipData.averageAmount = currentItem.averageAmount
      this.legendTooltipData.color = currentItem.borderColor
      this.classObject.top = event.y + 'px'
      this.classObject.left = event.x - 185 + 'px'
      this.isLegendTooltipVisible = true
    },
    hideLegendTooltip() {
      this.isLegendTooltipVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-tooltip {
  position: absolute;
  width: 170px;
  height: 70px;
  background-color: #333333;
  opacity: 0.9;
  color: #ffffff;
  border-radius: 4px;
  padding: 6px;
  font-size: 10px;

  &-title {
    div:first-child {
      height: 10px;
      width: 10px;
      margin-right: 4px;
    }
  }
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
